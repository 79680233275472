@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Tajawal:300,400,500,600,700');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", "Tajawal" !important;
}

.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons-outlined {
  font-family: "Material Icons Outlined" !important;
}
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.main {
  background: rgba(231, 235, 240, 0.6);
}
.text-darkblue {
  color: #0A3266;
}
.text-blue {
  color: #1C5BFE;
}
.text-gray {
  color: #6C84A3;
}
.translate-1\/2 {
  transform: translate(-50%, -50%) !important;
}
.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.2);
}
label input {
  display: none;
}
label p {
  overflow: hidden; 
  line-height: 1; 
  text-align: center; 
  border-radius: 4px; 
  border: #8599B3 solid 1px;
  font-size: 10pt; 
  color: #8599B3;
}
label p:hover {
  color: white;
  background: #1C5BFE;
  box-shadow: 0px 2px 6px rgba(108, 132, 163, 0.6);
  animation: fadeOut 1s linear;
}
[dir=ltr] label p {
  float: left;
}
[dir=rtl] label p {
  float: right;
}
.checked + p{
  color: white;
  background: #1C5BFE;
  box-shadow: 0px 2px 6px rgba(108, 132, 163, 0.6);
  animation: fadeOut 1s linear;
}
@keyframes fadeOut {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
input[type=checkbox] {
  transform: scale(1.5);
  background: #F4F9FF;
  border-radius: 3px;
}
input[type=checkbox] + label{
  color: #6C84A3;
  font-weight: normal;
  font-size: 14px;
}
input[type=checkbox]:checked + label{
  font-weight: 500;
  color: #0A3266;
}
.dot {
  height: 6px;
  width: 6px;
  background: #1C5BFE;
  border-radius: 50%;
}
.hr {
  width: 20px;
  border: 1px solid #D2DEFF;
}
.disabled {
  background: #CED6E0;
}
.myBlu-bg {
  background: #1C5BFE;
}
.myBlu-bg::after {
  content: '';
  position: absolute;
  top: 0;
  width: 60%;
  height: 100%;
  z-index: 0;
}
.bg-en::after {
  right: 0;
  background: linear-gradient(to bottom right, transparent 49.8%, #0A3266 50%);
}
.bg-ar::after {
  left: 0;
  background: linear-gradient(to bottom left, transparent 49.8%, #0A3266 50%);
}
.tracker-img-ar {
  left: 96px;
  top: 0;
}
.content {
  margin-right: 350px;
}
.content-ar {
  margin-left: 350px;
}
@media (max-width: 1279px) {
  .content {
    margin-right: 200px;
  }
  .content-ar {
    margin-left: 200px;
  }
  .tracker-img {
    right: 96px;
    top: 0;
  }
  .tracker-img-ar {
    left: 70px;
    top: 0;
  }
}
@media (max-width: 1023px) {
  .content, .content-ar {
    margin: 0px !important;
  }
}
.logo {
  font-family: "Montserrat";
  background: white;
  padding: 1px 3px !important;
  font-size: 14px;
  color: #e11f26;
  border: 2px solid #e11f26;
}
.show-modal {
  visibility: visible;
  opacity: 1 !important;
  pointer-events: auto !important;
}
.loading-window {
  backdrop-filter: blur(3px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.loading-window > #loading {
  position: fixed;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 9999999;
  left: calc(50% - 25px);
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: #1C5BFE;
  animation: rotate .5s ease infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
.modal-window {
  background: rgba(10, 50, 102, 0.4);
  backdrop-filter: blur(6px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  z-index: 99;
}
.modal-window > div {
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.8);
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-close {
  cursor: pointer;
  color: #aaa;
  font-size: 120%;
  position: absolute;
  top: 0px;
  text-align: center;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}
.modal-close {
  right: 10px;
}
[dir=rtl] .modal-close {
  left: 10px;
}
.alert {
  top: -200px;
  position: fixed;
  left: calc(50% - 160px);
  animation-name: notify;
  animation-duration: 2s;
  z-index: 999;
}
@keyframes notify {
  0%   {top:-200px;}
  25%  {top:30px;}
  50%  {top:30px;}
  75%  {top:30px;}
  100% {top:-200px;}
}
.alert .inner {
  box-sizing: border-box;
  width: 280px;
  display: inline-flex;
  padding: 10px !important;
  border-radius: 5px;
}
.alert.error .inner {
  text-align: left;
  color: #fff;
  border: 1px solid #EA5455;
  background-color: #EA5455;
}
.alert.success .inner {
  text-align: left;
  color: #fff;
  border: 1px solid #28C76F;
  background-color: #28C76F;
}
.right-1\/2 {
  right: 50%;
  transform: translateX(50%);
}
.left-1\/2 {
  left: 50%;
  transform: translateX(-50%);
}
button {
  height: 60px;
}
.material-icons-round {
  font-family: "Material Icons Round" !important;
}
button:focus {
  outline: none;
}
.button-enable {
  color: white;
  background: #1C5BFE;
  box-shadow: 0px 2px 6px rgba(108, 132, 163, 0.6);
}
.button-disable {
  color: #6C84A3;
  background: #9DADC260;
  cursor: not-allowed;
}
.button-second {
  color: #6C84A3;
  background: #9DADC260;
}
.border-gray {
  border: solid 3px #D1D5DB;
}
.validate-input {
  color: red;
}
.modal-subtitle {
  .text-align {
    text-align: center;
  }
}
[dir=rtl] .text-right {
  text-align: left !important;
}
[dir=rtl] .text-left {
  text-align: right !important;
}
[dir=rtl] .pr-2 {
  padding-left: 0.5rem !important;
  padding-right: 0rem !important;
}
[dir=rtl] .ml-2 {
  margin-right: .5rem !important;
  margin-left: 0rem !important;
}
.switch {
  transform: scaleX(-1) !important;
}
[dir=rtl] .lg\:ml-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}
@media only screen and (max-width: 991px) {
  [dir=rtl] .lg\:ml-0 {
    margin-right: 1.75rem !important;
    margin-left: 0rem !important;
  }
}
.slide-right {
  animation: slideRight 0.5s ease-in-out;
}
@keyframes slideRight {
  from {
    position: relative;
    left: calc(100% + 175px);
  }
  to {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
.slide-left {
  animation: slideLeft 0.5s ease-in-out;
}
@keyframes slideLeft {
  from {
    position: relative;
    right: calc(100% + 175px);
  }
  to {
    position: relative;
    right: 50%;
    transform: translateX(50%);
  }
}